@import '/src/theme/settings/_color';

.button-approve,
.button-approve:focus {
    color: #ffffff;
    background-color: @approve-color;
    border-color: @approve-color;

    &:hover {
        background-color: @approve-color-lighter;
        border-color: @approve-color-lighter;
    }

    &:active {
        background-color: @approve-color-dark;
        border-color: @approve-color-dark;
    }
}
