@import 'modules/_buttons.less';

@font-face {
    font-family: 'Plastic Sans';
    src: url(./assets/fonts/plastic-sans.eot);
    src: url(./assets/fonts/plastic-sans.eot?#iefix) format('embedded-opentype'),
        url(./assets/fonts/plastic-sans.woff2) format('woff2'),
        url(./assets/fonts/plastic-sans.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Plastic Sans';
    src: url(./assets/fonts/plastic-sans-medium.eot);
    src: url(./assets/fonts/plastic-sans-medium.eot?#iefix) format('embedded-opentype'),
        url(./assets/fonts/plastic-sans-medium.woff2) format('woff2'),
        url(./assets/fonts/plastic-sans-medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Plastic Sans';
    src: url(./assets/fonts/plastic-sans-bold.eot);
    src: url(./assets/fonts/plastic-sans-bold.eot?#iefix) format('embedded-opentype'),
        url(./assets/fonts/plastic-sans-bold.woff2) format('woff2'),
        url(./assets/fonts/plastic-sans-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

* {
    font-family: 'Plastic Sans', Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.table-content {
    white-space: nowrap;
}

.full-width,
.ant-input-number.full-width {
    width: 100%;
}

.ant-table {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.ant-dropdown-menu-title-content {
    span[role='img'] {
        margin-right: 8px;
    }
}

.error-message {
    color: @alert-color;
}

// Multiline label in horizontal form layout, usage: <Form.Item className="wrap-label">...</Form.Item>
.wrap-label {
    .ant-form-item-label {
        white-space: normal;

        >label {
            height: auto;
            align-items: end;
        }
    }
}